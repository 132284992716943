<template>
  <v-app>
    <v-app-bar app color="#8f5c45" flat>
      <Header />
    </v-app-bar>

    <router-view></router-view>

    <v-Footer>
      <Footer />
    </v-Footer>
  </v-app>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>

<template>
  <v-content>
    <v-img
      src="../assets/images/header.jpg"
      class="align-center"
      height="700px"
    >
      <v-overlay absolute>
        <v-col style="text-align: center">
          <span
            class="hidden-xs-only"
            style="font-size: 10em; font-family: Yesteryear; color: #edbd44"
            >Services and Prices</span
          >
        </v-col>
        <v-img
        src="../assets/images/logofullmobile.png"
        class="d-sm-none align-center"
        width="300px"
        height="550px"
      >
      </v-img>
      </v-overlay>
    </v-img>

    <v-container fluid>
      <v-row dense justify-center class="mt-10">
        <v-col
          md="4"
          v-for="card in cards"
          :key="card"
          :cols="12"
          :align="center"
        >
          <v-card
            class="rounded-card"
            style="
              background: linear-gradient(0.25turn, #f8c053, #f3a93c, #f8c053);
            "
          >
            <v-card-title class="preistypo">
              {{ card.title }}
            </v-card-title>

            <v-card-text>
              <v-row v-for="row in card.text" :key="row" dense>
                <v-col cols="5" style="align-content: center">
                  <span>{{ row.leistung }} </span>
                </v-col>
                <v-col class="preistypo" cols="6" style="align-content: center">
                  {{ row.preis }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <div class="mt-5"></div>
  </v-content>
</template>

<script>
export default {
  name: "About",
  alignments: ["end"],
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Yesteryear&display=swap");
.header {
  font-family: "Yesteryear", cursive;
  color: #f8c053;
  font-size: 10em;
}

.cardTitle {
  justify-content: center;
  padding: 100px;
  font-size: 40px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: #fcb900;
}
.test {
  background-color: red;
  width: 100px;
  height: 100px;
  flex-direction: row;

  align-content: center;
}
.preistypo {
  font-weight: bold;
}

/* .overlay-image { */
/* position: relative; */
/* } */

.overlay-text {
  position: absolute;
}
.rounded-card {
  border-radius: 10px;
}
</style>

<script>
export default {
  data: () => ({
    cards: [
      {
        title: "Traditional Thai Massage",
        text: [
          { preis: "60 €", leistung: "45 Mins" },
          { preis: "70 €", leistung: "60 Mins" },
          { preis: "100 €", leistung: "90 Mins" },
        ],
      },
      {
        title: "Aroma Oil Massage",
        text: [
          { preis: "60 €", leistung: "45 Mins" },
          { preis: "70 €", leistung: "60 Mins" },
          { preis: "100 €", leistung: "90 Mins" },
        ],
      },
      {
        title: "Thai Oil Massage",
        text: [
          { preis: "60 €", leistung: "45 Mins" },
          { preis: "70 €", leistung: "60 Mins" },
          { preis: "100 €", leistung: "90 Mins" },
        ],
      },
      {
        title: "Foot Reflexology Massage",
        text: [
          { preis: "50 €", leistung: "30 Mins" },
          { preis: "60 €", leistung: "45 Mins" },
          { preis: "70 €", leistung: "60 Mins" },
        ],
      },
      {
        title: "Back, Head & Shoulder",
        text: [
          { preis: "50 €", leistung: "30 Mins" },
          { preis: "60 €", leistung: "45 Mins" },
          { preis: "70 €", leistung: "60 Mins" },
        ],
      },
      {
        title: "Sport Massage",
        text: [
          { preis: "75 €", leistung: "60 Mins" },
          { preis: "105 €", leistung: "90 Mins" },
          { preis: "-", leistung: "-" },
        ],
      },
      {
        title: "Thai Herbal Compress",
        text: [
          { preis: "120 €", leistung: "90 Mins" },
          //  {preis:"50",leistung:"haapy end"},
          //  {preis:"50",leistung:"haapy end"}
        ],
      },
      {
        title: "Hot Stone Massage",
        text: [
          { preis: "120 €", leistung: "90 Mins" },
          //  {preis:"50",leistung:"haapy end"},
          //  {preis:"50",leistung:"haapy end"}
        ],
      },
    ],
  }),
};
</script>

<template>
  <v-content> Not Found </v-content>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {
      items: [],
    };
  },
};
</script>

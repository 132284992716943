<template>
  <v-container>
    <v-footer v-bind="localAttrs" :padless="padless">
      <v-card color="#381A14" class="mx-auto" outlined>
        <v-card-text class="text-center">
          <v-btn
            href="https://www.facebook.com/profile.php?id=100086288441171"
            target="_blank"
            rel="noreferrer noopener"
            v-for="icon in icons"
            :key="icon"
            class="mx-4 orange--text"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="pt-0 yellow--text text-center">
          Narin Thai Massage<br />
          45 route de Thionville<br />
          L-2611 LUXEMBOURG<br />
          +352 661 555 532<br />
          <a href="mailto:contact@narinthaimassage.lu" style="color: yellow;font-weight: bold;"
            >contact@narinthaimassage.lu</a
          >
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="yellow--text text-center">
          {{ new Date().getFullYear() }} — <strong>Narin Thai Massage</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook"],
  }),
};
</script>

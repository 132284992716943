<template>
  <v-container>
    <v-toolbar color="#8f5c45" flat>
      <img
        class="mr-3"
        :src="require('../assets/images/logo-narin.png')"
        width="40px"
      />
      <v-toolbar-title class="hidden-xs-only"
        >Narin Thai Massage</v-toolbar-title
      >

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn text>
          <router-link to="/" class="white--text flatened">Home</router-link>
        </v-btn>

        <v-btn text>
          <router-link to="/about" class="white--text flatened"
            >Prices</router-link
          >
        </v-btn>

        <v-btn text>
          <router-link to="/contact" class="white--text flatened"
            >Contact</router-link
          >
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
  </v-container>
</template>

<script>
export default {
  name: "Header",

  data: () => ({}),
};
</script>
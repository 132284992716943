<template>
  <v-container>
    <div class="block galleryblock">
      <h2 class="text-center">Our Services</h2>
      <v-row>
        <v-col
          md="4"
          v-for="item in items"
          :key="item.id"
          class="d-flex child-flex"
          cols="12"
        >
          <v-card color="#D2691E" class="mx-auto" max-width="400">
            <v-img
              :src="item.src"
              aspect-ratio="1"
              class="white--text align-end"
              height="250"
            >
              <template v-slot:placeholder>
                <v-row align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <v-card-title class="justify-center yellow--text">
              {{ item.text }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Gallery",

  data() {
    return {
      items: [
        {
          id: 1,
          src: require("../assets/images/massage.jpg"),
          text: "Traditional Thai Massage",
        },
        {
          id: 2,
          src: require("../assets/images/stone.jpg"),
          text: "Hot Stone Massage",
        },
        {
          id: 3,
          src: require("../assets/images/aroma.jpg"),
          text: "Aroma Oil Massage",
        },
        {
          id: 4,
          src: require("../assets/images/neck.jpg"),
          text: "Back, Head, Shoulder Massage",
        },
        {
          id: 5,
          src: require("../assets/images/foot.jpg"),
          text: "Reflexologie",
        },
        {
          id: 6,
          src: require("../assets/images/tissue.jpg"),
          text: "Deep Tissue Massage",
        },
      ],
    };
  },
};
</script>
